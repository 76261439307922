<template>
  <div>
    <b-table
      :fields="fields"
      :items="prices"
      responsive
      hover
    >
      <template #head()="item">
        <span class="text-nowrap">
          {{ $t(item.field.label) }}
        </span>
      </template>
      <!-- Price Type -->
      <template #cell(type)="{ value }">
        <b-badge variant="light-primary">
          {{ getTypeLabel(value) }}
        </b-badge>
      </template>
      <!-- Netto -->
      <template #cell(offerPriceNew)="{ value, item }">
        <div
          v-if="value"
          class="d-flex align-items-center"
        >
          <div
            v-b-tooltip.html
            :title="$t('offer.product.lastPrice') + `:<br /> ${priceFormat(item.offerPriceOld)}`"
          >
            <feather-icon
              :icon="getCompareIcon(value, item.offerPriceOld)"
              :class="getCompareColor(value, item.offerPriceOld)"
              size="20"
              class="mr-25"
            />
            {{ value || 0 | priceFormat }} PLN
          </div>
        </div>
        <span v-else>
          <feather-icon icon="MinusIcon" />
        </span>
      </template>
      <template #cell(offerPriceOld)="{ value }">
        <div
          v-if="value"
          class="d-flex align-items-center"
        >
          {{ value || 0 | priceFormat }} PLN
        </div>
        <span v-else>
          <feather-icon icon="MinusIcon" />
        </span>
      </template>
      <!-- Created At -->
      <template #cell(createdAt)="{ value }">
        <span>{{ formatDate(value.date, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
      </template>
      <!-- Updated By -->
      <template #cell(createdBy)="{ value }">
        <avatar :user="value" />
      </template>

      <!--      Empty      -->
      <template #empty>
        <div
          class="text-center py-1 text-primary font-weight-bold"
        >
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />
          {{ $t('NoData') }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'PriceHistory',
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    prices: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
  },
  data: () => ({
    fields: [
      { key: 'type', label: 'task.Type' },
      { key: 'createdAt', label: 'EditedAt' },
      { key: 'offerPriceNew', label: 'offer.product.newPrice' },
      { key: 'offerPriceOld', label: 'offer.product.lastPrice' },
      // { key: 'offerPriceNew', label: 'offer.product.netPriceListGrossEnd' },
      // { key: 'minimumPriceGrossEndNew', label: 'offer.product.minimumNetPriceGrossEnd' },
      // { key: 'promotionPriceGrossEndNew', label: 'offer.product.reducedPriceGrossEnd' },
      { key: 'createdBy', label: 'User' },
    ],
  }),
  methods: {
    formatDate,
    getTypeLabel(value) {
      if (value === 'MANY') return this.$i18n.t('MassShopPriceUpdate')
      if (value === 'ONE') return this.$i18n.t('Edit')

      return '-'
    },
    priceFormat(value) {
      return this.$options.filters.priceFormat(value)
    },
    getCompareIcon(newValue, oldValue) {
      return newValue < oldValue ? 'ChevronDownIcon' : 'ChevronUpIcon'
    },
    getCompareColor(newValue, oldValue) {
      return newValue < oldValue ? 'text-danger' : 'text-success'
    },
  },
}
</script>

<style scoped>

</style>
