var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.prices,"responsive":"","hover":""},scopedSlots:_vm._u([{key:"head()",fn:function(item){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")])]}},{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.getTypeLabel(value))+" ")])]}},{key:"cell(offerPriceNew)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],attrs:{"title":_vm.$t('offer.product.lastPrice') + ":<br /> " + (_vm.priceFormat(item.offerPriceOld))}},[_c('feather-icon',{staticClass:"mr-25",class:_vm.getCompareColor(value, item.offerPriceOld),attrs:{"icon":_vm.getCompareIcon(value, item.offerPriceOld),"size":"20"}}),_vm._v(" "+_vm._s(_vm._f("priceFormat")(value || 0))+" PLN ")],1)]):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(offerPriceOld)",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(value || 0))+" PLN ")]):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(value.date, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, _vm.$i18n.locale)))])]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value}})]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center py-1 text-primary font-weight-bold"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }